import api from '@/api'
import DateService from '@/helpers/DateService'
import moment from 'moment'
import 'moment-range'

let Q = require('q')

const state = {
  medioPersonal: [],
  turnos: []
}

const getters = {
  medioPersonal: (state) => {
    return state.medioPersonal
  },
  turnos: (state) => {
    return state.turnos
  },
  // Get guardias (no borradas) por fecha
  getMediosPersonalesByDate: (state) => (date) => {
    return state.medioPersonal.filter(x => x.FECHA === DateService.formatDate(date, 'YYYY-MM-DD HH:mm:ss.SSS'))
  },

  getMedioAsignado: (state) => (idPersonal, fecha) => {
    const momentFecha = moment.utc(fecha) // Conversión a UTC

    return state.medioPersonal.find(({ ID_PERSONAL, FECHA_ENTRADA, FECHA_SALIDA }) =>
      ID_PERSONAL === idPersonal &&
      momentFecha.isBetween(moment.utc(FECHA_ENTRADA), moment.utc(FECHA_SALIDA), null, '[]')
    ) || null
  }
}

const mutations = {
  SET_MEDIO_PERSONAL (state, medioPersonal) {
    state.medioPersonal = medioPersonal
  },
  SOCKET_addMedioPersonal (state, mediosPersonales) {
    mediosPersonales.forEach(async element => {
      let indexFound = state.medioPersonal.findIndex(x => x.ID_MEDIO_PERSONAL === element.ID_MEDIO_PERSONAL)
      if (indexFound !== -1) {
        this.notify('Guardia modificada', `Se ha modificado una guardia correctamente (${DateService.parseDate(element.FECHA).format('DD/MM/YYYY')})`)
        state.medioPersonal[indexFound] = element
        return
      }
      this.notify('Guardia creada', `Se ha creado una guardia correctamente (${DateService.parseDate(element.FECHA).format('DD/MM/YYYY')})`)
      state.medioPersonal.push(element)
    })
  },
  SOCKET_removeMedioPersonal (state, idMedioPersonal) {
    this.notify('Guardia eliminada', 'Se ha eliminado 1 guardia(s)')
    state.medioPersonal = state.medioPersonal.filter(x => x.ID_MEDIO_PERSONAL !== idMedioPersonal)
  },
  SET_TURNOS (state, turnos) {
    state.turnos = turnos
  },
  SET_TURNO (state, turno) {
    state.turnos.push(turno)
  },
  UNSET_TURNO (state, ID_TURNO) {
    state.turnos.splice(state.turnos.findIndex(x => x.ID_TURNO === ID_TURNO), 1)
  }
}

const actions = {
  async fetchMediosPersonales ({ commit }) {
    let deferred = Q.defer()
    try {
      const response = await api.medio.getMedioPersonal()
      let formattedData = methods.formatDates(response.data)
      commit('SET_MEDIO_PERSONAL', formattedData)
      deferred.resolve()
    } catch (err) {
      console.error(err)
      deferred.reject(err)
    }
    return deferred.promise
  },
  async fetchTurnos ({ commit }) {
    let deferred = Q.defer()

    try {
      const response = await api.medio.getTurnos()
      commit('SET_TURNOS', response.data)
      deferred.resolve()
    } catch (err) {
      console.error(err)
      deferred.reject(err)
    }
    return deferred.promise
  },

  // data: array expected
  async addMedioPersonal ({ commit }, data) {
    let deferred = Q.defer()
    try {
      const response = await api.medio.setMedioPersonal(data)
      commit('SOCKET_addMedioPersonal', methods.formatDates(data))
      // to_do => socket en backend
      deferred.resolve()
    } catch (err) {
      this.notify('Error al añadir medioPersonal', 'Error al añadir medioPersonal', 'error')
      console.error(err)
      deferred.reject(err)
    }
  },
  async removeMedioPersonal ({ commit }, data) {
    let deferred = Q.defer()
    try {
      const response = await api.medio.deleteMedioPersonal(data)
      commit('SOCKET_removeMedioPersonal', data)
      // to_do => socket en backend
      deferred.resolve()
    } catch (err) {
      this.notify('Error al borrar medioPersonal', 'Error al añadir medioPersonal', 'error')
      console.error(err)
      deferred.reject(err)
    }
  },
  async addTurno ({ commit }, data) {
    let deferred = Q.defer()
    try {
      const response = await api.medio.setTurno(data)
      commit('SET_TURNO', response.data)
      deferred.resolve()
    } catch (err) {
      this.notify('Error al añadir turno', 'Error al añadir turno', 'error')
      console.error(err)
      deferred.reject(err)
    }
  },
  async removeTurno ({ commit }, data) {
    let deferred = Q.defer()
    try {
      const response = await api.medio.deleteTurno(data)
      commit('UNSET_TURNO', data)
      deferred.resolve()
    } catch (err) {
      this.notify('Error al borrar turno', 'Error al añadir turno', 'error')
      console.error(err)
      deferred.reject(err)
    }
  }
}

const methods = {
  formatDates (data) {
    data.map(element => {
      element.FECHA = DateService.parseDate(element.FECHA).local().format('YYYY-MM-DD HH:mm:ss.SSS')
      element.FECHA_ENTRADA = DateService.parseDate(element.FECHA_ENTRADA).local().format('YYYY-MM-DD HH:mm:ss.SSS')
      element.FECHA_SALIDA = DateService.parseDate(element.FECHA_SALIDA).local().format('YYYY-MM-DD HH:mm:ss.SSS')
    })
    return data
  }
}

const module = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}

export default module

<template>
  <v-dialog v-model="show" persistent max-width="600px">
    <v-card>
      <v-toolbar color="primaryLight" dark dense>
        <v-toolbar-title>{{ accionDialog }}</v-toolbar-title>
      </v-toolbar>

      <v-card-text v-if="details">
        <v-container>
          <!-- Columna 1 -->
          <v-row>
            <v-col cols="6">
              <v-list dense>
                <v-list-item v-if="details.NOMBRE">
                  <v-list-item-content>
                    <v-list-item-title>Nombre</v-list-item-title>
                    <v-list-item-subtitle>{{ details.NOMBRE }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item v-if="details.APELLIDOS">
                  <v-list-item-content>
                    <v-list-item-title>Apellidos</v-list-item-title>
                    <v-list-item-subtitle>{{ details.APELLIDOS }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item v-if="details.ALIAS">
                  <v-list-item-content>
                    <v-list-item-title>Alias</v-list-item-title>
                    <v-list-item-subtitle>{{ details.ALIAS }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item v-if="details.DNI">
                  <v-list-item-content>
                    <v-list-item-title>DNI</v-list-item-title>
                    <v-list-item-subtitle>{{ details.DNI }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item v-if="details.TELEFONO">
                  <v-list-item-content>
                    <v-list-item-title>Teléfono 1</v-list-item-title>
                    <v-list-item-subtitle>{{ details.TELEFONO }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item v-if="details.CORREO">
                  <v-list-item-content>
                    <v-list-item-title>Correo 1</v-list-item-title>
                    <v-list-item-subtitle>{{ details.CORREO }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item v-if="details.ISSI">
                  <v-list-item-content>
                    <v-list-item-title>ISSI</v-list-item-title>
                    <v-list-item-subtitle>{{ details.ISSI }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <!-- medios -->
                <v-list-item v-if="details.MEDIO">
                  <v-list-item-content>
                    <v-list-item-title>Nombre medio</v-list-item-title>
                    <v-list-item-subtitle>{{ details.MEDIO }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item v-if="details.TELEFONO_EXTERNO">
                  <v-list-item-content>
                    <v-list-item-title>Teléfono 1</v-list-item-title>
                    <v-list-item-subtitle>{{ details.TELEFONO_EXTERNO }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item v-if="details.CORREO_1">
                  <v-list-item-content>
                    <v-list-item-title>Correo 1</v-list-item-title>
                    <v-list-item-subtitle>{{ details.CORREO_1 }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item v-if="details.ISSI_POS">
                  <v-list-item-content>
                    <v-list-item-title>ISSI</v-list-item-title>
                    <v-list-item-subtitle>{{ details.ISSI_POS }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item v-if="details.BASE">
                  <v-list-item-content>
                    <v-list-item-title>Base</v-list-item-title>
                    <v-list-item-subtitle>{{ details.BASE }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item v-if="details.CATEGORIA">
                  <v-list-item-content>
                    <v-list-item-title>Categoría</v-list-item-title>
                    <v-list-item-subtitle>{{ details.CATEGORIA }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item v-if="details.TIPO">
                  <v-list-item-content>
                    <v-list-item-title>Tipo</v-list-item-title>
                    <v-list-item-subtitle>{{ details.TIPO }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item v-if="details.NUMERO_COMPONENTES">
                  <v-list-item-content>
                    <v-list-item-title>Componentes</v-list-item-title>
                    <v-list-item-subtitle>{{ details.NUMERO_COMPONENTES }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>

            <!-- Columna 2 -->
            <v-col cols="6">
              <v-list dense>
                <v-list-item v-if="details.INFRAESTRUCTURA && details.INFRAESTRUCTURA.NOMBRE">
                  <v-list-item-content>
                    <v-list-item-title>Base</v-list-item-title>
                    <v-list-item-subtitle>{{ details.INFRAESTRUCTURA.NOMBRE }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item v-if="details.TITULO_PROFESIONAL && details.TITULO_PROFESIONAL.TITULO_PROFESIONAL">
                  <v-list-item-content>
                    <v-list-item-title>Título profesional</v-list-item-title>
                    <v-list-item-subtitle>{{ details.TITULO_PROFESIONAL.TITULO_PROFESIONAL }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item v-if="details.PERSONAL_TIPO && details.PERSONAL_TIPO.CATEGORIA && details.PERSONAL_TIPO.CATEGORIA.CATEGORIA">
                  <v-list-item-content>
                    <v-list-item-title>Categoría</v-list-item-title>
                    <v-list-item-subtitle>{{ details.PERSONAL_TIPO.CATEGORIA.CATEGORIA }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item v-if="details.PERSONAL_TIPO && details.PERSONAL_TIPO.PERSONAL_TIPO">
                  <v-list-item-content>
                    <v-list-item-title>Tipo</v-list-item-title>
                    <v-list-item-subtitle>{{ details.PERSONAL_TIPO.PERSONAL_TIPO }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item v-if="details.IMAGEN_PERSONAL">
                  <v-list-item-content>
                    <v-list-item-title>Foto</v-list-item-title>
                    <v-list-item-subtitle>
                      <v-img
                        :src="details.IMAGEN_PERSONAL"
                        alt="Foto"
                        max-width="150"
                        max-height="150"
                        class="mx-auto my-4"
                        rounded
                      />
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item v-if="details.DESCRIPCION">
                  <v-list-item-content>
                    <v-list-item-title>Descripción</v-list-item-title>
                    <v-list-item-subtitle>{{ details.DESCRIPCION }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn text color="red" @click="closeDialog">Cerrar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

import api from '@/api'

export default {
  props: {
    details: {
      type: Object,
      required: true,
      default: () => ({
        // objeto vacío
      })
    },
    accionDialog: String,
    show: Boolean
  },
  watch: {
    details () {
      if (this.details) {
        this.fetchImagenes()
      }
    }
  },
  methods: {
    async fetchImagenes () {
      let imagenesOld = []
      let urls = []

      imagenesOld = (await api.imagenPersonal.getImagenesPersonal(this.details.ID_PERSONAL)).data

      for (let i = 0; i < imagenesOld.length; i++) {
        let binary = ''
        let bytes = new Uint8Array(imagenesOld[i].IMAGEN.data)
        let len = bytes.byteLength
        for (let j = 0; j < len; j++) {
          binary += String.fromCharCode(bytes[j])
        }

        let imageUrl = 'data:image/jpeg;base64,' + window.btoa(binary)
        urls.push(imageUrl)
      }

      if (urls.length > 0) {
        this.details.IMAGEN_PERSONAL = urls[0]
      }
    },

    closeDialog () {
      this.$emit('close')
    }
  }
}
</script>

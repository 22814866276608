<template>
  <v-dialog v-model="show" persistent max-width="100%" max-height="80%">
    <v-card>
      <v-toolbar color="primaryLight" dark dense>
        <v-toolbar-title>Listado de incendios</v-toolbar-title>
      </v-toolbar>

      <v-card-text>
        <v-container>
          <v-row align="center">
            <v-col cols="10">
              <v-autocomplete
                v-model="tableFilters"
                return-object
                :items="headers.filter(x => x.text !== 'Ocultar/Mostrar')"
                :item-text="(h) => {return h.text}"
                item-title="text"
                label="Mostrar campos"
                closable-chips
                multiple
                clearable
                dense
                outlined
              >
                <template #selection="{ item, index }">
                  <v-chip v-if="index < maxVisible" :key="item.value" small>
                    {{ item.text }}
                  </v-chip>
                  <span v-else-if="index === maxVisible" :key="index" small>
                    {{ computedHeaders.length - maxVisible - 1 }} más
                  </span>
                </template>
              </v-autocomplete>
            </v-col>

            <v-col cols="1">
              <v-btn icon color="teal" @click="resetFilters()">
                <v-icon>mdi-cached</v-icon>
              </v-btn>
            </v-col>

            <v-col cols="1">
              <v-btn
                icon
                color="teal"
                @click="mostrarFilAv = !mostrarFilAv; increaseFirstContainerHeight()"
              >
                <v-icon v-if="!mostrarFilAv">mdi-filter-menu</v-icon>
                <v-icon v-else>mdi-filter-minus-outline</v-icon>
              </v-btn>
            </v-col>
          </v-row>

          <v-row class="mt-0 mb-1">
            <v-col>
              <v-text-field label="Nombre" v-model="nombreSelected" outlined dense @blur="filterFechaIncendio" />
            </v-col>

            <v-col>
              <v-menu ref="fechaInit" v-model="showFechaInit" :close-on-content-click="false" :nudge-right="40" transition="scale-transition">
                <template #activator="{ on, attrs }">
                  <v-text-field
                    :value="fechaInitFormatted"
                    @click:clear="fechaInit = null"
                    label="Fecha desde"
                    prepend-icon="mdi-clock-time-four-outline"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    outlined
                    dense
                    clearable
                    hide-details
                  />
                </template>
                <v-date-picker
                  v-model="fechaInit"
                  @input="$refs.fechaInit.save(fechaInit)"
                  locale="es-ES"
                  :max="fechaEnd"
                />
              </v-menu>
            </v-col>

            <v-col>
              <v-menu ref="fechaEnd" v-model="showFechaEnd" :close-on-content-click="false" :nudge-right="40" transition="scale-transition">
                <template #activator="{ on, attrs }">
                  <v-text-field
                    :value="fechaEndFormatted"
                    label="Fecha hasta"
                    @click:clear="fechaEnd = null"
                    prepend-icon="mdi-clock-time-four-outline"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    outlined
                    dense
                    clearable
                    hide-details
                  />
                </template>
                <v-date-picker
                  v-model="fechaEnd"
                  @input="$refs.fechaEnd.save(fechaInit)"
                  locale="es-ES"
                  :min="fechaInit"
                />
              </v-menu>
            </v-col>

            <v-col>
              <v-text-field label="Municipio" v-model="nombreIncendioSelected" outlined dense />
            </v-col>

            <v-col>
              <v-text-field label="Localidad" v-model="localidadIncendioSelected" outlined dense />
            </v-col>
          </v-row>

          <v-row class="mt-0 mb-1" v-show="mostrarFilAv">
            <v-col>
              <v-select
                label="Estado"
                v-model="estadoSelected"
                :items="tiposIncendioEstado"
                item-text="INCENDIO_ESTADO"
                return-object
                clearable
                outlined
                dense
              />
            </v-col>

            <v-col>
              <v-select
                label="Tipo Detección"
                v-model="tipoDeteccionSelected"
                :items="tiposDeteccion"
                item-text="DETECCION"
                return-object
                clearable
                outlined
                dense
              />
            </v-col>

            <v-col>
              <v-select
                label="Nivel"
                v-model="nivelSelected"
                :items="tiposIncendioNivel"
                item-text="INCENDIO_NIVEL"
                return-object
                clearable
                outlined
                dense
              />
            </v-col>
          </v-row>

          <v-row class="mt-1">
            <v-col>
              <span class="subtitle-1 black--text">Nº de incendios: {{ incendiosFilter.length }}</span>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-data-table
                class="table"
                :headers="computedHeaders"
                :items="incendiosFilter"
                item-key="ID_INCENDIO"
                fixed-header
                hide-default-footer
                disable-pagination
              >
                <template #no-data>
                  <p>Datos no disponibles.</p>
                </template>

                <template #[`item.FECHA_CREACION`]="{ item }">
                  <span>{{ item.FECHA_CREACION | formatDate }}</span>
                </template>

                <template #[`item.ESTADO`]="{ item }">
                  <span>{{ item.ESTADOS[0].TIPO_INCENDIO_ESTADO.INCENDIO_ESTADO }}</span>
                </template>

                <template #[`item.TIPO_DETECCION`]="{ item }">
                  <span>{{ item.DETECCION.DETECCION }}</span>
                </template>

                <template #[`item.OCULTO`]="{ item }">
                  <v-tooltip bottom>
                    <template #activator="{ on, attrs }">
                      <v-btn icon v-bind="attrs" v-on="on" :disabled="item.ESTADOS[0].TIPO_INCENDIO_ESTADO.VALUE !== 4 && item.ESTADOS[0].TIPO_INCENDIO_ESTADO.VALUE !== 5" @click.stop.prevent="mostrarOcultarIncendio(item)">
                        <v-icon v-show="item.OCULTO" color="black">mdi-eye-off</v-icon>
                        <v-icon v-show="!item.OCULTO" color="black">mdi-eye</v-icon>
                      </v-btn>
                    </template>
                    <span v-show="!item.OCULTO">Ocultar</span>
                    <span v-show="item.OCULTO">Mostrar</span>
                  </v-tooltip>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn text color="red" @click="cerrar">Cerrar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import { normalizeText, countFilteredItems, clearFilters } from '../../helpers/Utils.js'

export default {
  props: {
    show: Boolean
  },

  data: () => ({
    showFechaInit: false,
    showFechaEnd: false,
    fechaInit: null,
    fechaEnd: null,
    nombreIncendioSelected: null,
    localidadIncendioSelected: null,
    estadoSelected: null,
    tipoDeteccionSelected: null,
    nivelSelected: null,
    nombreSelected: null,
    tableFilters: [],

    headers: [
      { text: 'Nombre', value: 'NOMBRE', align: 'left', sortable: true, width: '10%', initialShow: true },
      { text: 'Municipio', value: 'MUNICIPIO', align: 'left', sortable: true, initialShow: true },
      { text: 'Localidad', value: 'LOCALIDAD', align: 'left', sortable: true, initialShow: true },
      { text: 'Fecha de inicio', value: 'FECHA_CREACION', align: 'left', width: '20%', sortable: true, initialShow: true },
      { text: 'Estado', value: 'ESTADO', align: 'left', sortable: true, initialShow: true },
      { text: 'Tipo Detección', value: 'TIPO_DETECCION', align: 'left', width: '10%', sortable: true, initialShow: true },
      { text: 'Ocultar/Mostrar', value: 'OCULTO', align: 'center', sortable: false }
    ],

    maxVisible: 10,

    mostrarFilAv: false,

    incendiosFilter: []
  }),

  computed: {
    ...mapGetters('incendio', [
      'tiposIncendioEstado', 'tiposDeteccion', 'tiposIncendioNivel'
    ]),

    incendios () {
      return this.$store.getters['incendio/incendiosAll']
    },

    fechaInitFormatted () {
      let date = this.$date.parseDate(this.fechaInit, 'YYYY-MM-DD')
      return date.isValid() ? this.$date.formatDate(date, 'DD/MM/YYYY') : ''
    },
    fechaEndFormatted () {
      let date = this.$date.parseDate(this.fechaEnd, 'YYYY-MM-DD')
      return date.isValid() ? this.$date.formatDate(date, 'DD/MM/YYYY') : ''
    },

    filteredItemsCount () {
      return countFilteredItems(this.incendiosFilter, () => true)
    },

    computedHeaders () {
      return this.headers.filter(x => (this.tableFilters.length > 0 && x.text === 'Ocultar/Mostrar') || this.tableFilters.includes(x))
    }
  },

  watch: {
    show () {
      if (this.show) {
        this.incendiosFilter = this.incendios
        this.filterFechaIncendio()
        this.resetFilters()
      }
    },
    incendios () {
      this.filterFechaIncendio()
    },
    fechaInit () {
      this.filterFechaIncendio()
    },
    fechaEnd () {
      this.filterFechaIncendio()
    },
    nombreIncendioSelected () {
      this.filterFechaIncendio()
    },
    localidadIncendioSelected () {
      this.filterFechaIncendio()
    },
    estadoSelected () {
      this.filterFechaIncendio()
    },
    tipoDeteccionSelected () {
      this.filterFechaIncendio()
    },
    nivelSelected () {
      this.filterFechaIncendio()
    },
    nombreSelected () {
      this.filterFechaIncendio()
    }
  },

  methods: {
    filterFechaIncendio () {
      this.incendiosFilter = this.incendios

      if (this.fechaInit) {
        this.incendiosFilter = this.incendiosFilter.filter(x => x.FECHA_CREACION >= this.fechaInit)
      }

      if (this.fechaEnd) {
        this.incendiosFilter = this.incendiosFilter.filter(x => x.FECHA_CREACION <= this.fechaEnd)
      }

      if (this.nombreIncendioSelected) {
        const normalizedSearch = normalizeText(this.nombreIncendioSelected)
        this.incendiosFilter = this.incendiosFilter.filter(x =>
          normalizeText(x.MUNICIPIO).includes(normalizedSearch)
        )
      }

      if (this.nombreSelected) {
        const normalizedSearch = normalizeText(this.nombreSelected)
        this.incendiosFilter = this.incendiosFilter.filter(x =>
          normalizeText(x.NOMBRE).includes(normalizedSearch)
        )
      }

      if (this.localidadIncendioSelected) {
        const normalizedSearch = normalizeText(this.localidadIncendioSelected)
        this.incendiosFilter = this.incendiosFilter.filter(x =>
          normalizeText(x.LOCALIDAD).includes(normalizedSearch)
        )
      }

      if (this.estadoSelected) {
        this.incendiosFilter = this.incendiosFilter.filter(x =>
          x.ESTADOS[0].TIPO_INCENDIO_ESTADO.INCENDIO_ESTADO === this.estadoSelected.INCENDIO_ESTADO
        )
      }

      if (this.tipoDeteccionSelected) {
        this.incendiosFilter = this.incendiosFilter.filter(x =>
          x.ID_TIPO_DETECCION === this.tipoDeteccionSelected.ID_TIPO_DETECCION
        )
      }

      if (this.nivelSelected) {
        this.incendiosFilter = this.incendiosFilter.filter(x =>
          x.ID_TIPO_INCENDIO_NIVEL === this.nivelSelected.ID_TIPO_INCENDIO_NIVEL
        )
      }
    },

    mostrarOcultarIncendio (incendio) {
      let oculto = !incendio.OCULTO
      this.$store.dispatch('incendio/ocultarIncendio', {
        ID_INCENDIO: incendio.ID_INCENDIO,
        OCULTO: oculto
      })
    },

    resetFilters () {
      this.tableFilters = this.headers.filter(x => x.initialShow)

      const filters = {
        fechaInit: this.fechaInit,
        fechaEnd: this.fechaEnd,
        nombreIncendioSelected: this.nombreIncendioSelected,
        localidadIncendioSelected: this.localidadIncendioSelected,
        estadoSelected: this.estadoSelected,
        tipoDeteccionSelected: this.tipoDeteccionSelected,
        nivelSelected: this.nivelSelected,
        nombreSelected: null
      }

      clearFilters(filters)

      // Actualizar con valores iniciales
      Object.keys(filters).forEach(key => {
        this[key] = filters[key]
      })

      this.filterFechaIncendio() // Volver a aplicar los filtros con los valores reiniciados
    },

    increaseFirstContainerHeight () {
      this.$nextTick(() => {
        this.calculateAvailableSpace()
      })
    },

    calculateAvailableSpace () {
      if (this.$refs.firstContainer) {
        const firstContainer = this.$refs.firstContainer
        const firstContainerRect = firstContainer.getBoundingClientRect()
        const firstContainerBottom = firstContainerRect.top + firstContainerRect.height

        // Obtener la altura del viewport
        const viewportHeight = window.innerHeight

        // Calcular el espacio disponible en vh
        this.availableSpace = ((viewportHeight - firstContainerBottom) / viewportHeight) * 100
      }
    },

    mounted () {
      this.increaseFirstContainerHeight()
      this.resetFilters()
      this.maxVisible = this.headers.filter(x => x.initialShow).length - 1

      // Calcular el espacio disponible al montar el componente
      this.calculateAvailableSpace()

      // Actualizar el espacio disponible si la ventana se redimensiona
      window.addEventListener('resize', this.calculateAvailableSpace)
    },

    beforeDestroy () {
    // Limpiar el event listener para evitar fugas de memoria
      window.removeEventListener('resize', this.calculateAvailableSpace)
    },

    cerrar () {
      this.$emit('cerrar')
    }
  }
}
</script>

<style scoped>
  /* Estilos para que la tabla tenga un max-height y pueda crecer */
  .table {
    max-height: 500px;
  }
  .v-data-table {
    max-height: 40vh;
    overflow-y: auto;
    overflow: auto;
  }
  .v-data-table >>> .v-data-table__wrapper { /* Header fijo */
    overflow: unset;
  }
  .v-input__selections {
    max-height: 20px;
    overflow-y: auto;
  }
  .v-data-table >>> .v-data-footer {
    position: relative; /* Cambia a relative para evitar el solapamiento */
    bottom: 0;
    width: 100vw;
    background: white;
  }
  .v-card-text {
    max-height: none !important;
    overflow: unset !important;
  }
  .v-row.mt-2 {
    margin-top: 8px !important;
  }
</style>

<template>
  <v-dialog v-model="show" persistent max-width="400px">
    <v-card>
      <v-toolbar color="primaryLight" dark dense>
        <v-toolbar-title>{{ editMedio.MEDIO }}</v-toolbar-title>
      </v-toolbar>

      <v-card-text>
        <v-form v-model="valid" @submit.prevent="aceptar">
          <v-container fluid>
            <!-- Aviso -->
            <v-row>
              <v-col sm="10">
                <vx-date-text-edit v-model="editMedio.FECHA_AVISO" label="Fecha aviso" icon="esri-icon-calendar" :min="fechaIncendio" :max="editMedio.FECHA_LLEGADA" not-future required />
              </v-col>
            </v-row>

            <!-- Llegada -->
            <v-row align="center">
              <v-col sm="10">
                <vx-date-text-edit
                  v-model="editMedio.FECHA_LLEGADA" label="Fecha llegada" icon="esri-icon-calendar"
                  :min="editMedio.FECHA_AVISO" :max="editMedio.FECHA_SALIDA" not-future
                />
              </v-col>

              <v-col sm="2">
                <v-tooltip bottom>
                  <template #activator="{ on, attrs }">
                    <v-btn icon color="red" v-bind="attrs" v-on="on" :disabled="!editMedio.FECHA_LLEGADA" @click="removeFechaLlegada">
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </template>
                  <span>Eliminar fecha llegada.</span>
                </v-tooltip>
              </v-col>
            </v-row>

            <!-- Salida -->
            <v-row align="center">
              <v-col sm="10">
                <vx-date-text-edit
                  v-model="editMedio.FECHA_SALIDA" label="Fecha salida" icon="esri-icon-calendar"
                  :min="editMedio.FECHA_LLEGADA" :max="editMedio.FECHA_LLEGADA_BASE" not-future
                  :disabled="disableFechaSalida"
                />
              </v-col>

              <v-col sm="2">
                <v-tooltip bottom>
                  <template #activator="{ on, attrs }">
                    <v-btn icon color="red" v-bind="attrs" v-on="on" :disabled="!editMedio.FECHA_SALIDA" @click="removeFechaSalida">
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </template>
                  <span>Eliminar fecha salida.</span>
                </v-tooltip>
              </v-col>
            </v-row>

            <!-- Llegada base -->
            <v-row align="center">
              <v-col sm="10">
                <vx-date-text-edit
                  v-model="editMedio.FECHA_LLEGADA_BASE" label="Fecha llegada base" icon="esri-icon-calendar"
                  :min="editMedio.FECHA_SALIDA" :max="editMedio.FECHA_FIN_DESCANSO" not-future
                  :disabled="disableFechaLlegadaBase"
                />
              </v-col>

              <v-col sm="2">
                <v-tooltip bottom>
                  <template #activator="{ on, attrs }">
                    <v-btn icon color="red" v-bind="attrs" v-on="on" :disabled="!editMedio.FECHA_LLEGADA_BASE" @click="removeFechaLlegadaBase">
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </template>
                  <span>Eliminar fecha llegada base.</span>
                </v-tooltip>
              </v-col>
            </v-row>

            <!-- Fin descanso -->
            <v-row align="center">
              <v-col sm="10">
                <vx-date-text-edit
                  v-model="editMedio.FECHA_FIN_DESCANSO" label="Fecha fin descanso" icon="esri-icon-calendar"
                  :min="editMedio.FECHA_LLEGADA_BASE" :disabled="disableFechaFinDescanso"
                />
              </v-col>

              <v-col sm="2">
                <v-tooltip bottom>
                  <template #activator="{ on, attrs }">
                    <v-btn icon color="red" v-bind="attrs" v-on="on" :disabled="!editMedio.FECHA_FIN_DESCANSO" @click="removeFechaFinDescanso">
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </template>
                  <span>Eliminar fecha fin descanso.</span>
                </v-tooltip>
              </v-col>
            </v-row>

            <!-- Sector -->
            <v-row>
              <v-col>
                <v-select v-model="editMedio.ID_SECTOR" :items="sectores" prepend-icon="esri-icon-description" label="Sector" item-text="SECTOR" item-value="ID_SECTOR" single-line autocomplete />
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-btn text color="red" @click="deleteMedioIncendio">Eliminar act.</v-btn>
        <v-spacer />
        <v-btn text color="red" @click="cancelar">Cancelar</v-btn>
        <v-btn text color="green" @click="aceptar" :disabled="!valid">Aceptar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    show: Boolean,
    idIncendio: String,
    medioSector: Object
  },

  data: () => ({
    valid: true,

    dateFormat: 'DD/MM/YYYY HH:mm',
    editMedio: {}
  }),

  computed: {
    incendio () {
      return this.$store.getters['incendio/getIncendioByID'](this.idIncendio)
    },

    fechaIncendio () {
      let fechaIncendio = null

      if (this.incendio) {
        fechaIncendio = this.$date.parseDate(this.incendio.FECHA_CREACION)
      }

      return fechaIncendio
    },

    sectores () {
      let sectores = []

      if (this.incendio) {
        sectores = this.incendio.SECTORES
      }

      return sectores
    },

    disableFechaSalida () {
      return !this.editMedio.FECHA_LLEGADA || this.$date.parseDate(this.editMedio.FECHA_LLEGADA, this.dateFormat) >= this.$date.now()
    },
    disableFechaLlegadaBase () {
      return !this.editMedio.FECHA_SALIDA || this.$date.parseDate(this.editMedio.FECHA_SALIDA, this.dateFormat) >= this.$date.now()
    },
    disableFechaFinDescanso () {
      return !this.editMedio.FECHA_LLEGADA_BASE || this.$date.parseDate(this.editMedio.FECHA_LLEGADA_BASE, this.dateFormat) >= this.$date.now()
    }
  },

  watch: {
    show () {
      if (this.show) {
        this.editMedio = JSON.parse(JSON.stringify(this.medioSector))

        // 8061 para datetime
        this.editMedio.FECHA_AVISO = this.$date.formatDate(this.editMedio.FECHA_AVISO, this.dateFormat)
        this.editMedio.FECHA_LLEGADA = this.editMedio.FECHA_LLEGADA ? this.$date.formatDate(this.editMedio.FECHA_LLEGADA, this.dateFormat) : null
        this.editMedio.FECHA_SALIDA = this.editMedio.FECHA_SALIDA ? this.$date.formatDate(this.editMedio.FECHA_SALIDA, this.dateFormat) : null
        this.editMedio.FECHA_LLEGADA_BASE = this.editMedio.FECHA_LLEGADA_BASE ? this.$date.formatDate(this.editMedio.FECHA_LLEGADA_BASE, this.dateFormat) : null
        this.editMedio.FECHA_FIN_DESCANSO = this.editMedio.FECHA_FIN_DESCANSO ? this.$date.formatDate(this.editMedio.FECHA_FIN_DESCANSO, this.dateFormat) : null
      } else {
        this.editMedio = {}
      }
    }
  },

  methods: {
    removeFechaLlegada () {
      this.editMedio.FECHA_LLEGADA = null
      this.editMedio.FECHA_SALIDA = null
      this.editMedio.FECHA_LLEGADA_BASE = null
      this.editMedio.FECHA_FIN_DESCANSO = null
    },

    removeFechaSalida () {
      this.editMedio.FECHA_SALIDA = null
      this.editMedio.FECHA_LLEGADA_BASE = null
      this.editMedio.FECHA_FIN_DESCANSO = null
    },

    removeFechaLlegadaBase () {
      this.editMedio.FECHA_LLEGADA_BASE = null
      this.editMedio.FECHA_FIN_DESCANSO = null
    },

    removeFechaFinDescanso () {
      this.editMedio.FECHA_FIN_DESCANSO = null
    },

    deleteMedioIncendio () {
      let msg = 'Eliminando la actuación del medio: ' + this.editMedio.MEDIO
      this.$root.$confirmDialog.open('¿Está seguro?', msg).then(result => {
        if (result) {
          this.$emit('deleteMedioIncendio', this.medioSector)
        }
      })
    },

    cancelar () {
      this.$emit('cancelar')
    },

    async aceptar () {
      let editMedio = JSON.parse(JSON.stringify(this.editMedio))
      editMedio.FECHA_AVISO = this.$date.parseDate(editMedio.FECHA_AVISO, this.dateFormat)
      editMedio.FECHA_LLEGADA = editMedio.FECHA_LLEGADA ? this.$date.parseDate(editMedio.FECHA_LLEGADA, this.dateFormat) : null
      editMedio.FECHA_SALIDA = editMedio.FECHA_SALIDA ? this.$date.parseDate(editMedio.FECHA_SALIDA, this.dateFormat) : null
      editMedio.FECHA_LLEGADA_BASE = editMedio.FECHA_LLEGADA_BASE ? this.$date.parseDate(editMedio.FECHA_LLEGADA_BASE, this.dateFormat) : null
      editMedio.FECHA_FIN_DESCANSO = editMedio.FECHA_FIN_DESCANSO ? this.$date.parseDate(editMedio.FECHA_FIN_DESCANSO, this.dateFormat) : null
      editMedio.NUM_COMPONENTES = this.medioSector.NUM_COMPONENTES

      editMedio.FECHA_AVISO_PLANIF = editMedio.FECHA_AVISO
      editMedio.FECHA_LLEGADA_PLANIF = editMedio.FECHA_LLEGADA

      try {
        if (editMedio.FECHA_LLEGADA && this.incendio &&
        this.incendio.ESTADOS[0].TIPO_INCENDIO_ESTADO.VALUE ===
      this.$store.state.incendio.tiposIncendioEstado[0].VALUE) {
          const estadoIncendio = {
            ID_INCENDIO_ESTADO: this.$uuid.createUUID(),
            ID_INCENDIO: this.incendio.ID_INCENDIO,
            ID_TIPO_INCENDIO_ESTADO:
            this.$store.state.incendio.tiposIncendioEstado[1].ID_TIPO_INCENDIO_ESTADO,
            FECHA: this.$date.now(),
            TIPO_INCENDIO_ESTADO: this.$store.state.incendio.tiposIncendioEstado[1]
          }
          await this.$store.dispatch('incendio/addEstadoIncendio', estadoIncendio)
        }
      } catch (error) {
        console.error('Error al actualizar el medio:', error)
      }
      this.$emit('aceptar', editMedio)
    }
  }
}
</script>

<style scoped>
</style>

<template>
  <v-card-text>
    <v-container class="py-0 ma-0" fluid ref="firstContainer">
      <v-row>
        <v-col cols="10">
          <v-autocomplete
            v-model="tableFilters"
            return-object
            :items="headers.filter(x => x.text !== 'Acciones')"
            :items-text="(h) => h.text"
            item-title="text"
            label="Mostrar campos"
            closable-chips
            multiple
            clearable
            dense
            outlined
          >
            <template #selection="{ item, index }">
              <v-chip v-if="index < maxVisible" :key="item.value" small>
                {{ item.text }}
              </v-chip>
              <span v-else-if="index === maxVisible" :key="index">
                {{ computedHeaders.length - maxVisible - 1 }} más
              </span>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col cols="1">
          <v-btn icon color="teal" @click="resetFilters()">
            <v-icon>mdi-cached</v-icon>
          </v-btn>
          <v-btn
            icon
            color="teal"
            @click="mostrarFilAv = !mostrarFilAv;increaseFirstContainerHeight()"
          >
            <v-icon v-if="!mostrarFilAv">mdi-filter-menu</v-icon>
            <v-icon v-else>mdi-filter-minus-outline</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="1">
          <v-btn icon color="teal" @click="downloadExcelEquipos()">
            <v-icon>mdi-file-excel-outline</v-icon>
          </v-btn>
          <v-btn icon color="red" @click="exportarEquiposPDF()">
            <v-icon>mdi-file-pdf-box</v-icon>
          </v-btn>
        </v-col>
      </v-row>

      <v-row class="mt-0">
        <v-col cols="3">
          <v-text-field v-model="search" label="Búsqueda general" clearable />
        </v-col>
        <v-col cols="3">
          <v-autocomplete
            v-model="tipoEquipoSelected"
            :items="tiposEquipo"
            :item-text="(tipo) => tipo.NOMBRE"
            label="Tipo de equipo"
            clearable
          />
        </v-col>
        <v-col cols="3">
          <v-text-field v-model="nombreEquipo" label="Nombre equipo" clearable />
        </v-col>
      </v-row>

      <!-- Filtros avanzados -->
      <v-row class="mt-0" v-show="mostrarFilAv">
        <v-col cols="3">
          <v-text-field v-model="descripcionSelected" label="Descripción" clearable />
        </v-col>
        <v-col cols="3">
          <v-text-field v-model="fechaCreacionSelected" label="Fecha creación" hint="DD/MM/YYYY" clearable />
        </v-col>
      </v-row>

      <div class="text-center my-2">
        <v-chip color="blue" dark>Hay {{ filteredItemsCount }} resultados</v-chip>
      </div>

      <v-data-table
        class="table"
        :headers="computedHeaders"
        :items="equiposFilter"
        :search="search"
        fixed-header
        disable-pagination
        hide-default-footer
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
      >
        <template #no-data>
          <p>Datos no disponibles.</p>
        </template>
        <template #TIPO_EQUIPO="{ item }">
          <span>{{ item.TIPO_EQUIPO ? item.TIPO_EQUIPO.NOMBRE : "" }}</span>
        </template>

        <template #[`item.FECHA_CREACION`]="{ item }">
          <span>{{ item.FECHA_CREACION ? item.FECHA_CREACION : "" | formatDate }}</span>
        </template>

        <template #[`item.MEDIOS`]="{ item }">
          <span>{{ item.MEDIO_EQUIPOs ? mediosEquipos(item.MEDIO_EQUIPOs) : "" }}</span>
        </template>

        <template #[`item.ACCIONES`]="{ item }">
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                icon
                @click="showEditEquipoDialog(item)"
              >
                <v-icon color="teal">mdi-pencil</v-icon>
              </v-btn>
            </template>
            <span>Editar</span>
          </v-tooltip>
          <!-- <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  :disabled="disabledByRol"
                  icon
                  @click="toggleActivateMedio(item)"
                >
                  <v-icon :color="item.ACTIVO ? 'success' : 'gray'">{{ item.ACTIVO ? 'mdi-lock-open-check-outline' : 'mdi-lock-off-outline' }}</v-icon>
                </v-btn>
              </template>
              <span>{{ item.ACTIVO ? 'Desactivar' : 'Activar' }}</span>
            </v-tooltip> -->
        </template>
      </v-data-table>
    </v-container>

    <!-- Diálogo de agregar/editar equipo -->
    <vx-dialog-add-edit-equipo
      :show="showDialog"
      :isEdit="isEdit"
      :editedEquipo="editEquipo"
      @cancelar="showDialog = false"
      @addEquipo="acceptAddEquipo"
      @editEquipo="acceptEditEquipo"
    />
  </v-card-text>
</template>

<script>
import { mapActions } from 'vuex'
import DialogAddEditEquipo from './DialogAddEditEquipo.vue'
import * as ExcelHelper from '../../helpers/ExcelHelper'
import { normalizeText, countFilteredItems, clearFilters } from '../../helpers/Utils.js'

import constants from '../../helpers/constants'

export default {
  components: {
    'vx-dialog-add-edit-equipo': DialogAddEditEquipo
  },

  props: {
    showDialogAddEdit: Boolean
  },

  data: () => ({
    headers: [
      { text: 'Nombre equipo', value: 'NOMBRE', align: 'left', sortable: true, initialShow: true },
      { text: 'Tipo', value: 'TIPO_EQUIPO.NOMBRE', align: 'left', sortable: true, initialShow: true },
      { text: 'Descripción', value: 'DESCRIPCION', align: 'left', sortable: true, initialShow: true },
      {
        text: 'Fecha de creación',
        value: 'FECHA_CREACION',
        align: 'left',
        sort: (a, b) => {
          if (!a || !a.FECHA) return -1
          if (!b || !b.FECHA) return 1
          return new Date(a.FECHA) - new Date(b.FECHA)
        },
        sortable: true,
        initialShow: true
      },
      { text: 'Medios', value: 'MEDIOS', align: 'left', sortable: false, initialShow: true },

      { text: 'Acciones', value: 'ACCIONES', align: 'center', width: '10%', sortable: false, initialShow: true }
    ],

    tableFilters: [],

    sortBy: 'NOMBRE',
    sortDesc: false,

    availableSpace: 0,
    maxVisible: 9,

    search: '',
    editEquipo: {},
    isEdit: false,
    showDialog: false,
    nombreEquipo: '',
    descripcionSelected: '',
    fechaCreacionSelected: '',
    tipoEquipoSelected: null,
    mostrarFilAv: false
  }),

  watch: {
    showDialogAddEdit () {
      this.showDialog = this.showDialogAddEdit
    }
  },

  computed: {
    equipos () {
      return this.$store.getters['equipo/equipos']
    },

    tiposEquipo () {
      return this.$store.getters['equipo/tiposEquipo']
    },

    disabledByRol () {
      // Deshabilita funciones dependiendo del rol
      return !this.$store.getters['usuario/isCurrentUsuarioAdmin']
    },

    filteredItemsCount () {
      return countFilteredItems(this.equiposFilter, item => true)
    },

    computedHeaders () {
      return this.headers.filter(x => (this.tableFilters.length > 0 && x.text === 'Acciones') || this.tableFilters.includes(x))
    },

    equiposFilter () {
      return this.equipos.filter((equipo) => {
        const matchesTipoEquipo =
      !this.tipoEquipoSelected ||
      (equipo.TIPO_EQUIPO && equipo.TIPO_EQUIPO.NOMBRE === this.tipoEquipoSelected)

        const matchesFechaCreacion =
      !this.fechaCreacionSelected ||
      (equipo.FECHA_CREACION && equipo.FECHA_CREACION.includes(this.fechaCreacionSelected))

        return (
          matchesTipoEquipo &&
      matchesFechaCreacion &&
      equipo.NOMBRE.toLowerCase().includes(this.search.toLowerCase()) &&
      (!this.nombreEquipo || equipo.NOMBRE.toLowerCase().includes(this.nombreEquipo.toLowerCase())) &&
      (!this.descripcionSelected || equipo.DESCRIPCION.toLowerCase().includes(this.descripcionSelected.toLowerCase()))
        )
      })
    }
  },

  methods: {
    ...mapActions('equipo', ['addEquipo', 'updateEquipo', 'deleteEquipo']),

    showEditEquipoDialog (item) {
      this.editEquipo = Object.assign({}, item)
      this.showDialog = true
      this.isEdit = true
    },

    resetFilters () {
      this.tableFilters = this.headers.filter(header => header.initialShow)

      this.search = ''
      this.nombreEquipo = ''
      this.descripcionSelected = ''
      this.fechaCreacionSelected = ''
      this.tipoEquipoSelected = null
    },

    increaseFirstContainerHeight () {
      // Forzar una actualización del DOM para reflejar la nueva altura
      this.$nextTick(() => {
        this.calculateAvailableSpace() // Recalcular el espacio disponible
      })
    },

    calculateAvailableSpace () {
      // Obtener la posición y altura del primer v-container
      const firstContainer = this.$refs.firstContainer
      const firstContainerRect = firstContainer.getBoundingClientRect()
      const firstContainerBottom = firstContainerRect.top + firstContainerRect.height

      // Obtener la altura del viewport
      const viewportHeight = window.innerHeight

      // Calcular el espacio disponible en vh
      this.availableSpace = ((viewportHeight - firstContainerBottom) / viewportHeight) * 100
    },

    downloadExcelEquipos () {
      ExcelHelper.downloadJSON2CSV(this.computedHeaders.filter(x => x.value !== 'ACCIONES'), this.equiposFilter)
    },

    toggleEnableMedio (medio) {
      if (medio.HABILITADO === true) {
        let msg = 'Deshabilitar el medio: ' + medio.MEDIO
        this.$root.$confirmDialog.open('¿Está seguro?', msg).then((result) => {
          if (result) {
            this.$store.dispatch('medio/disableMedio', medio)
          }
        })
      } else {
        this.$store.dispatch('medio/enableMedio', medio)
      }
    },

    toggleActivateMedio (medio) {
      // if (medio.ACTIVO === true) {
      //   let msg = 'Desactivar el medio: ' + medio.MEDIO
      //   this.$root.$confirmDialog.open('¿Está seguro?', msg).then((result) => {
      //     if (result) {
      //       this.$store.dispatch('medio/deactivateMedio', medio)
      //     }
      //   })
      // } else {
      //   this.$store.dispatch('medio/activateMedio', medio)
      // }
    },

    acceptAddEquipo (equipo) {
      this.addEquipo(equipo)
      this.showDialog = false
    },

    acceptEditEquipo (equipo) {
      this.updateEquipo(equipo)
      this.showDialog = false
    },

    mediosEquipos (mediosEquipos) {
      return mediosEquipos.map(x => x.MEDIO.MEDIO).join(', ')
    }
  },

  mounted () {
    this.maxVisible = this.headers.filter(x => x.initialShow).length - 1
    this.resetFilters()

    // Calcular el espacio disponible al montar el componente
    this.calculateAvailableSpace()
    window.addEventListener('resize', this.calculateAvailableSpace)
  },

  beforeDestroy () {
    // Limpiar el event listener para evitar fugas de memoria
    window.removeEventListener('resize', this.calculateAvailableSpace)
  }
}
</script>

<style scoped>
/* Estilos para que la tabla tenga un max-height y pueda crecer */
.table {
  width: 100vw;
  max-height: calc(100vh - 215px)
}
.v-data-table {
  overflow: auto;
}
.v-data-table >>> .v-data-table__wrapper {
  /* Header fijo */
  overflow: unset;
}
/* .v-data-table >>> .v-data-footer {
    position: fixed;
    bottom: 0;
    width: 100vw;
    background: white;
  } */
</style>

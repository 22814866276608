import api from '@/api'
import DateService from '@/helpers/DateService'
import Vue from 'vue'

let Q = require('q')

const state = {
  equipos: [],
  tiposEquipo: []
}

const getters = {
  equipos: (state) => state.equipos,
  tiposEquipo: (state) => state.tiposEquipo,
  getEquipoByID: (state) => (idEquipo) => {
    return idEquipo
      ? state.equipos.find(x => x.ID_EQUIPO.equalsIgnoreCase(idEquipo))
      : null
  }
}

const mutations = {
  SET_EQUIPOS (state, equipos) {
    state.equipos = equipos
  },
  SET_TIPOS_EQUIPO (state, tiposEquipo) {
    state.tiposEquipo = tiposEquipo
  },
  SOCKET_addEquipo (state, data) {
    state.equipos.push({ ...data })
    this.notify('Nuevo equipo', `Se ha añadido el equipo ${data.NOMBRE}`)
  },
  SOCKET_updateEquipo (state, data) {
    const positionEquipo = state.equipos.findIndex(x => x.ID_EQUIPO.equalsIgnoreCase(data.ID_EQUIPO))
    if (positionEquipo !== -1) {
      Vue.set(state.equipos, positionEquipo, { ...data })
      this.notify('Equipo actualizado', `Se ha actualizado el equipo ${data.NOMBRE}`)
    } else {
      console.warn('Equipo no encontrado para actualización:', data.ID_EQUIPO)
    }
  },
  SOCKET_deleteEquipo (state, data) {
    const index = state.equipos.findIndex(x => x.ID_EQUIPO.equalsIgnoreCase(data.ID_EQUIPO))
    if (index !== -1) {
      state.equipos.splice(index, 1)
      this.notify('Equipo eliminado', `Se ha eliminado el equipo ${data.NOMBRE}`)
    } else {
      console.warn('Equipo no encontrado para eliminación:', data.ID_EQUIPO)
    }
  }
}

const actions = {
  async fetchEquipos ({ commit }) {
    try {
      const response = await api.equipo.getEquipos()
      // console.log('MEDIOS', response.data.filter(x => x.MEDIO === '113'))
      commit('SET_EQUIPOS', response.data)
    } catch (err) {
      console.error('Error al obtener los equipos:', err)
      throw err
    }
  },
  async addEquipo ({ commit, dispatch }, data) {
    try {
      const response = await api.equipo.addEquipo(data)
      if (response.status === 200) {
        commit('SOCKET_addEquipo', response.data)
        this._vm.$socket.emit('addEquipo', response.data)
        await dispatch('fetchEquipos')
      }
    } catch (err) {
      this.notify('Error al añadir equipo', 'error')
      console.error(err)
      throw err
    }
  },
  async updateEquipo ({ commit, dispatch }, data) {
    try {
      const response = await api.equipo.updateEquipo(data)
      if (response.status === 200) {
        commit('SOCKET_updateEquipo', response.data)
        this._vm.$socket.emit('updateEquipo', response.data)
        await dispatch('fetchEquipos')
      }
    } catch (err) {
      this.notify('Error al editar equipo', 'El nombre del equipo ya existe', 'error')
      console.error(err)
      throw err
    }
  },
  async deleteEquipo ({ commit, dispatch }, data) {
    try {
      const response = await api.equipo.deleteEquipo(data)
      if (response.status === 200) {
        commit('SOCKET_deleteEquipo', data)
        this._vm.$socket.emit('deleteEquipo', data)
        await dispatch('fetchEquipos')
      }
    } catch (err) {
      console.error(err)
      throw err
    }
  },
  async fetchTiposEquipo ({ commit }) {
    try {
      const response = await api.equipo.getTiposEquipo()
      commit('SET_TIPOS_EQUIPO', response.data)
    } catch (err) {
      console.error('Error al obtener los tipos de equipo:', err)
      throw err
    }
  }
}

const module = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}

export default module
